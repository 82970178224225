import { DapcContact } from './DapcContact';
import { Dict } from 'src/app/models/Dict';

export class Dapc {
  id: number = null;

  //contact data
  dapc_contact_id: number = null;
  firstname:string=null;
  lastname:string=null;
  city:number=null;

  //demandeur
  beuzit_contact_id:number=null;
  beuzit_contact_city:string=null;
  beuzit_contact_firstname:string=null;
  beuzit_contact_lastname:string=null;
  beuzit_contact_phone:string=null;

  //dict data
  dict_id: number = null;
  noConsultationDuTeleservice: string =null;
  noAffaireDuResponsableDuProjet:string =null;
  
  label: string = null;
  description: string = null;
  data: any=null;
  files: any[] = [];
  status: string = null;
  sent_at: string = null;
  resent_at: string = null;

  contact: DapcContact = null;
  dict: Dict = null;

  company:string = null;

  constructor(id?: number | Object) {
    if (typeof id == 'object') {
      this.import(id);
    } else {
      this.id = id;
    }
  }

  import(data) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        switch (key) {
          case 'dapc':
            this.contact = new DapcContact(data[key]);
            break;
          case 'dict':
            this.dict = new Dict(data[key]);
            break;
          default:
            this[key] = data[key];
        }
      }
    }
  }
}