import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    redirectTo: 'auth/login'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(mod => mod.AuthModule),
  },
  // {
  //   path: 'admin',
  //   loadChildren: () => import('./modules/pages/pages.module').then(mod => mod.PagesModule),
  // },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'dict'], title: 'STATIC.NAV.DASHBOARD' },
    loadChildren: () => import('./modules/admin/dashboard/dashboard.module').then(mod => mod.DashboardModule),
  },
  {
    path: 'customers',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin'], title: 'STATIC.NAV.USERS' },
    loadChildren: () => import('./modules/admin/customers/customers.module').then(mod => mod.CustomersModule),
  },
  {
    path: 'parameters',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin'], title: 'STATIC.NAV.PARAMETERS' },
    loadChildren: () => import('./modules/admin/parameters/parameters.module').then(mod => mod.ParametersModule),
  },
  {
    path: 'lists',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin'], title: 'STATIC.NAV.LISTS' },
    loadChildren: () => import('./modules/admin/value-lists/value-lists.module').then(mod => mod.ValueListsModule),
  },
  {
    path: 'emails',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin','dict'], title: 'STATIC.NAV.EMAILS' },
    loadChildren: () => import('./modules/admin/emails/emails.module').then(mod => mod.EmailsModule),
  },
  {
    path: 'dicts',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin','dict'], title: 'STATIC.NAV.DICTS' },
    loadChildren: () => import('./modules/admin/dicts/dicts.module').then(mod => mod.DictsModule),
  },
  {
    path: 'dapc-contacts',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin','dict'], title: 'STATIC.NAV.DAPC_CONTACTS' },
    loadChildren: () => import('./modules/admin/dapc-contacts/dapc-contacts.module').then(mod => mod.DapcContactsModule),
  },
  {
    path: 'dapcs',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin','dict'], title: 'STATIC.NAV.DAPC' },
    loadChildren: () => import('./modules/admin/dapcs/dapcs.module').then(mod => mod.DapcsModule),
  },
  {
    path: 'dapcs-answers',
    data: { title: 'STATIC.NAV.DAPC' },
    loadChildren: () => import('./modules/admin/dapcs-answers/dapcs-answers.module').then(mod => mod.DapcsAnswersModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/errors/errors.module').then(mod => mod.ErrorsModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/errors/errors.module').then(mod => mod.ErrorsModule),
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

