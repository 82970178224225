import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'answer-form',
  templateUrl: './answer-form.component.html',
  styleUrls: ['./answer-form.component.scss']
})
export class AnswerFormComponent implements OnInit {

  _dapc_id = null;
  get dapc_id(): number {
    return this._dapc_id;
  }
  @Input() set dapc_id(value: number) {
    this._dapc_id = value;
  }

  _token = null;
  get token(): string {
    return this._token;
  }
  @Input() set token(value: string) {
    this._token = value;
  }
  @Input() form_sent: boolean = false;
  @Output() onAnswerDapc = new EventEmitter();
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef<HTMLInputElement>;

  public form: FormGroup = null;

  public refused_selected:boolean = false;
  public accepted_selected:boolean = false;
  public files: File[] = [];
  public disable_accepted: boolean = false;
  public disable_refused: boolean = false;
  

  constructor(private dataService:DataService,
              private translate:TranslateService,
              private zone:NgZone) {
    this.form = new FormGroup(
      {
        status: new FormControl(null, [Validators.required]),
        comment: new FormControl(null, [Validators.required]),
        file: new FormControl(null, []),
      },
      {
        updateOn: 'change',
      }
    );
  }

  ngOnInit(): void {
    this.form.get('status').valueChanges.subscribe(value=>{
      if(value == 'accepted'){
        this.accepted_selected = true;
        this.refused_selected = false;
        this.form.get('file').setValidators([Validators.required]);
        this.form.get('file').updateValueAndValidity();
        this.onAnswerDapc.emit('accept');
      }else if(value == 'refused'){
        this.refused_selected = true;
        this.accepted_selected = false;
        this.form.get('file').clearValidators();
        this.form.get('file').updateValueAndValidity();
        this.onAnswerDapc.emit('refuse');
      }
    });
  }

  ngAfterViewInit() {
    this.fileUpload.nativeElement.onchange = () => {
        let file = null;
        for (let index = 0; index < this.fileUpload.nativeElement.files.length; index++) {
            file = this.fileUpload.nativeElement.files[index];
        }
        this.updateValue(file);
    };
  }

  getFormData(answered_by:string = null){
    return this.setData(answered_by);  
  }

  setData(answered_by:string = null){
    const formData: FormData = new FormData();
    let data = this.form.value;

    for (let i in data) {
      if(i !== 'file' && data[i]){
        if (typeof data[i] === 'boolean') {
          formData.append(i, data[i] ? '1' : '0');
        } else { 
          formData.append(i, data[i])
        }
      }
    }

    if(this.form.get('file').value && this.form.get('file').value.length !== 0){
      for(let i=0; i< this.files.length; i++){
        formData.append('file'+i,this.files[i]);
      }
    }

    formData.append('dapc_id', this.dapc_id.toString());    
    formData.append('token',this.token);

    if(answered_by && answered_by==='answered_by_user'){
      formData.append('answered_by', 'user');
    }else{
      formData.append('answered_by', 'client');
    }
    return formData;
  }

  addFile(){
    this.fileUpload.nativeElement.value = null;
    this.fileUpload.nativeElement.click();
  }

  removeFile(file:File){
    let index = this.files.findIndex(f => f.name == file.name);
    if(index !== -1){
      this.files.splice(index,1);
      this.form.get('file').setValue(this.files);
    }
    
  }

  updateValue(file: File) {
    if (file) {
      this.files.push(file);
      this.form.get('file').setValue(this.files);
    }
  }
}
