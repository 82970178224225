import { PusherService } from './services/pusher.service';
import { AuthenticationService } from './services/authentication.service';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Status } from "src/app/models/enumStatus.model"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'beuzit';
  authenticated: boolean = false;
  public userStatus = Status;

  constructor(
    private auth: AuthenticationService,
    //private pusher: PusherService,
    private translate: TranslateService,

  ) {
    this.initTranslate();

    this.auth.currentAuthUser.subscribe((user) => {
      this.authenticated = user && user.exists() && user.status == this.userStatus.Validated ? true : false;
      if (this.authenticated) {
        //geolocation.getCurrentPosition();
      }
    });
  }

  async initTranslate() {
    const browserLang = this.translate.getBrowserLang();
    console.log('browserLang', browserLang);
    //const browserLang = null;
    let langs = ['fr'];
    // if (!environment.production) {
    //   langs = ['fr', 'en'];

    // }
    this.translate.addLangs(langs);
    let currentLang = browserLang;

    // let currentLang = await this.auth.getSavedOrDefaultLang();
    // if (!currentLang) {
    //   currentLang = browserLang;
    // }

    let lang = currentLang;
    if (langs.indexOf(lang) == -1) {
      lang = 'fr';
    }

    this.translate.setDefaultLang(lang);

    this.translate.use(lang).subscribe(() => {
      //this.menu();
    });

    this.translate.onLangChange.subscribe(() => {
      //this.menu();
    });
  }
}
